import { GetterTree } from 'vuex';
import { RootState, UploadState } from '@/types/state';

const getters: GetterTree<UploadState, RootState> = {
  progress: (s) => (id: number): [number, number] => [
    s.uploads[id]?.total || 1,
    s.uploads[id]?.loaded || 0,
  ],
  upload: (s) => (id: number) => s.uploads[id],
  list: (s) => s.uploads.filter((u) => !!u),
  listByCommissionId: (s) => (c: string) => s.uploads.filter((u) => u.commissionId === c),
  listByBusinessId: (s) => (c: string) => s.uploads.filter((u) => u.businessId === c),
};

export default getters;
