/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { UploadState } from '@/types/state';

const mutations: MutationTree<UploadState> = {
  incrementId(state) {
    state.nextId += 1;
  },
  cancel(state, [id, message]) {
    state.uploads[id].cancelToken.cancel(message);
    Vue.set(state.uploads[id], 'cancelled', true);
  },
  done(state, id) {
    Vue.set(state.uploads[id], 'done', true);
  },
  error(state, [id, e]) {
    Vue.set(state.uploads[id], 'error', e);
  },
  progress(state, [id, total, loaded]) {
    Vue.set(state.uploads[id], 'total', total);
    Vue.set(state.uploads[id], 'loaded', loaded);
  },
  set(state, upload) {
    Vue.set(state.uploads, upload.id, upload);
  },
  hide(state, id) {
    Vue.set(state.uploads[id], 'hidden', true);
  },
};

export default mutations;
