import { ActionTree } from 'vuex';
// eslint-disable-next-line import/no-cycle
import { RootState, UploadState } from '@/types/state';
import Axios from 'axios';
import { UploadInstructions } from '@/types';
import { RawLocation } from 'vue-router';

const actions: ActionTree<UploadState, RootState> = {
  async upload(
    { commit, dispatch },
    {
      file, instructions, afterUpload, onUploadCancel, onUploadError, routerTo,
      businessId, commissionId,
    }: {
      file: File,
      instructions: UploadInstructions | Promise<UploadInstructions>,
      afterUpload?: ((data: any) => any),
      onUploadCancel?: (() => void),
      onUploadError?: ((err: Error) => void),
      routerTo?: RawLocation,
      businessId?: string,
      commissionId?: string,
    },
  ): Promise<number> {
    const id: number = await dispatch('getNextId');

    const onUploadProgress = (event: ProgressEvent): void => {
      commit('progress', [id, event.total, event.loaded]);
    };

    const cancelTokenSource = Axios.CancelToken.source();

    const uploadRequest = (inst: UploadInstructions): Promise<unknown> => Axios.request({
      method: inst.method,
      url: inst.url,
      headers: inst.headers,
      params: inst.query,
      data: inst.params,
      onUploadProgress,
      cancelToken: cancelTokenSource.token,
      validateStatus(status) {
        return status === 204 || status === 200;
      },
      transformRequest: [
        // FormData Handler
        (data) => {
          console.debug('Upload Instructions', inst);

          if (!inst.file.startsWith('FORM_DATA_APPEND_PARAM')) return data;

          const form = new FormData();
          const params = Object.keys(data);

          params.forEach((param) => form.append(param, data[param]));

          form.append(inst.file.split(':', 2)[1], file);

          return form;
        },
        (data) => {
          if (inst.file !== 'POST_REQUEST_BODY') return data;

          return file;
        },
      ],
    }).catch((err) => {
      if (Axios.isCancel(err) && onUploadCancel) onUploadCancel();
      throw err;
    });

    let promise;

    if (instructions instanceof Promise) {
      promise = instructions.then(uploadRequest);
    } else {
      promise = uploadRequest(instructions);
    }

    promise.then((r) => {
      if (afterUpload) return afterUpload(r);
      return r;
    }).then((res) => {
      commit('done', id);

      return res;
    }).catch((err: Error) => {
      console.error(err);

      commit('error', [id, err]);

      if (Axios.isCancel(err) && onUploadCancel) return;

      if (onUploadError) onUploadError(err);

      throw err;
    });

    commit('set', {
      id,
      file,
      upload: promise,
      total: 1,
      loaded: 0,
      cancel: (message?: string) => {
        commit('cancel', [id, message]);
      },
      cancelToken: cancelTokenSource,
      routerTo,
      businessId,
      commissionId,
    });

    return id;
  },

  getNextId({ commit, state }) {
    const id = state.nextId;

    commit('incrementId');

    return id;
  },
};

export default actions;
