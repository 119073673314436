import { Module } from 'vuex';
import { RootState, UploadState } from '@/types/state';
import getters from './getters';
// eslint-disable-next-line import/no-cycle
import actions from './actions';
import mutations from './mutations';

function defaultState(): UploadState {
  return {
    finishedUploads: [],
    nextId: 1,
    uploads: [],
  };
}

const module: Module<UploadState, RootState> = {
  namespaced: true,
  state: defaultState(),
  getters,
  actions,
  mutations,
};

export default module;
